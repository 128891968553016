// @ts-nocheck
import Vue from "vue";
import globalVariables from '@/global';
import { AddToCartProduct, EComService, EventBus, ProductModel } from '@connectngo/sdk'

const ProductV2Mixin =  Vue.extend({
	props: {

	},
	data (){
		return {
		}
	},
	computed: {
		productWithEventGroup () {
			const productWithEvent = new Set();
			const eventProductIds = [];
			this.productsSelected.forEach(({ product }) => {
				if (product.data.event_group_id && !eventProductIds.includes(product.data.id)) {
					eventProductIds.push(product.data.id);
					productWithEvent.add(product);
				} else if (product.data.is_combo ) {
					product.data.combo_products.filter(combo => {
						if(combo.event_group_id && !eventProductIds.includes(combo.id)) {
							eventProductIds.push(combo.id);
							productWithEvent.add(new ProductModel(combo) )
						}
					})
				}
			});

			return Array.from(productWithEvent);
		},
		hasCalendar() {
			return this.productWithEventGroup.length > 0;
		},
		hasAllRequiredEvents() {
			return this.productWithEventGroup.length === 0 || Object.keys(this.selectedTimeSlots).length === this.productWithEventGroup.length
		},
		canAddToCart () {
			return !this.hasCalendar || (this.selectedDate !== null && this.hasAllRequiredEvents);
		}
	},
	methods: {
		formatArrayOfProduct() {
			return Array.from(this.productsSelected.entries()).map(([key, value]) => {

				const productPrice = this.getProductDynamicPrice(value.id, value.quantity) ?? (value.product.data.price * value.quantity).toFixed(2)
				return {
					id : key,
					name : this.$options.filters.translatable(
						value.product.data.name,
						value.product.data.name_i18n,
						this.$i18n.locale
					),
					event_group_id: value.product.data.event_group_id,
					price : parseFloat(productPrice?.amount ?? productPrice),
					quantity : value.quantity,
					modifiers : value.modifiers ? value.modifiers : []
				}
			});
		},
		formatModifiers(modifiers, eventId) {
			let formattedModifiers = [];
			modifiers.forEach(value => {
				formattedModifiers.push({
					productId: value.modifier.id,
					quantity: value.quantity,
					eventId: eventId,
					price: value.modifier.price,
				})
			});
			return formattedModifiers;
		},
		formatComboProducts(product) {
			let comboData = [];
			product.data.combo_products.forEach(comboProduct => {
				const productQty = product.data.combo_quantities.find(quantity => quantity.product_id === comboProduct.id).quantity;
				comboData.push({
					productId : comboProduct.id,
					quantity : productQty,
					eventId :  this.selectedTimeSlots?.[comboProduct.id]?.event_id ?? null,
				})
			})
			return comboData;
		},
		updateModifierQuantity(product, modifier, quantity) {
			if (quantity > 0) {
				this.addModifierToProduct(product, modifier, quantity);
			} else {
				this.removeModifierFromProduct(product, modifier);
			}
		},
		addModifierToProduct(product, modifier, quantity) {
			if (this.productsSelected.has(product.data.id)) {
				const productData = this.productsSelected.get(product.data.id);

				if (!productData.modifiers) {
					productData.modifiers = new Map();
				}

				productData.modifiers.set(modifier.id, { modifier, quantity });

				this.productsSelected.set(product.data.id, productData);

				this.productsSelected = new Map(this.productsSelected);
			}
		},
		removeModifierFromProduct(product, modifier) {
			if (this.productsSelected.has(product.data.id)) {
				const productData = this.productsSelected.get(product.data.id);

				if (productData.modifiers && productData.modifiers.has(modifier.id)) {
					productData.modifiers.delete(modifier.id);
					this.productsSelected.set(product.data.id, productData);
				}this.productsSelected = new Map(this.productsSelected);
			}
		},
		addToCart() {
			this.addToCartLoading = true;
			const productsDataFormat = Array.from(this.productsSelected.entries()).map(([key, value]) => {
				const eventId = this.selectedTimeSlots?.[value.product.data.id]?.event_id ?? null;

				return new AddToCartProduct({
					productId : value.id,
					quantity : value.quantity,
					eventId :  eventId,
					// TODO : Adjustment for combo
					modifiers : value.modifiers ? this.formatModifiers(value.modifiers, eventId) : [],
					comboItems : value.product.data.is_combo ? this.formatComboProducts(value.product): [],
				})
			});

			const hasAtLeastOneEventId = productsDataFormat.some(product => product.eventId !== null);

			return new EComService().addMultipleProductsToCart(productsDataFormat)
				.then(data => {
					globalVariables.cart = data;
					EventBus.publish("CART_UPDATED", data);
					if (hasAtLeastOneEventId) {
						this.closeModal();
					}
					if (this.isCrossSell) {
						this.$router.push({ name: "cart" });
					}
					this.$snack(this.$i18n.t("product.cartUpdated"));
					this.$emit('addToCartSuccess', productsDataFormat)
				})
				.catch(err => this.$handleError(this, err))
				.finally(() => this.addToCartLoading = false)
		},
	},
	watch: {}
});

export default ProductV2Mixin;
