<template>
	<div v-if="showModal" class="side-modal-overlay" @click.self="closeModal">
		<div class="side-modal" :class="{ show: showModal }">
			<div class="side-modal-content pa-3">
				<!-- Section 1: Date Picker -->
				<ProductPackageSection
					v-if="hasCalendar"
					:number="1"
					:title="$t('product.card1.title')">
					<ProductsDatePicker
						:productsSelected="productsSelected"
						@dateSelected="handleDateSelected"
					/>
				</ProductPackageSection>

				<!-- Section 2: Time Pickers -->
				<ProductPackageSection
					v-if="hasCalendar"
					:number="2"
					:title="$t('product.card2.title')">
					<ProductsTimePicker
						class="mt-5"
						v-for="(product, index) in productWithEventGroup"
						v-if="product.data.event_group_id"
						:key="product.data.id"
						:product="product"
						:products-selected="Array.from(productsSelected.values())"
						:date="selectedDate"
						@timeSelected="(slot) => handleTimeSelected(product, slot)"
					/>
				</ProductPackageSection>

				<!-- Section 3: Sub Total List -->
				<ProductPackageSection
					:number="hasCalendar ? 3 : null"
					:title="$t('product.card3.title')">
					<ProductsSubTotalList :products="productsFormat"/>
				</ProductPackageSection>

				<!-- Buttons -->
				<div class="d-flex justify-end pr-4">
					<v-btn class="cancel-button mr-3" outlined @click="closeModal">
						{{ $t('btn.cancel') }}
					</v-btn>
					<v-btn
						class="add-to-cart-button" outlined
						:disabled="!canAddToCart"
						:loading="addToCartLoading"
						@click="addToCart">
						{{ $t('btn.addToCart') }}
					</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ProductPackageSection from './ProductPackageSection';
	import ProductsDatePicker from './ProductsDatePicker';
	import ProductsTimePicker from './ProductsTimePicker';
	import ProductsSubTotalList from './ProductsSubTotalList';
	import ProductV2Mixin from '@/mixins/ProductV2Mixin';

	export default {
		name: 'ProductPackageSelector',
		mixins: [ProductV2Mixin],
		components: {
			ProductPackageSection,
			ProductsDatePicker,
			ProductsTimePicker,
			ProductsSubTotalList,
		},
		props: {
			productsSelected: {
				type: Map,
				required: true,
			},
			isCrossSell: {
				type: Boolean,
				required: false,
				default: false
			}
		},
		data() {
			return {
				showModal: false,
				selectedTimeSlots: {},
				selectedDate : null,
				selectedDatePrices : null,
				addToCartLoading : false,
				productsFormat : [],
			};
		},
		computed: {
		},
		watch : {
			showModal(newVal, oldVal) {
				if(newVal) {
					this.productsFormat = this.formatArrayOfProduct()
				} else {
					this.productsFormat = []
					this.selectedTimeSlots = {}
					this.selectedDate = null
					this.selectedDatePrices= null
				}
			},
			selectedDate() {
				this.selectedTimeSlots = {}
			}
		},
		methods: {
			handleTimeSelected(product, slot) {
				this.$set(this.selectedTimeSlots, product.product?.data.id ?? product.data?.id , slot);
				this.productsFormat = this.formatArrayOfProduct();
			},
			handleDateSelected(date, datePrices) {
				this.selectedDate = date;
				this.selectedDatePrices = datePrices;
				this.productsFormat = this.formatArrayOfProduct()
			},
			getProductDynamicPrice(productId, quantity) {
				return this.selectedTimeSlots[productId] ? (this.selectedTimeSlots[productId]?.price * quantity).toFixed(2) : this.selectedDatePrices?.prices?.find(item => item.product_id === productId);
			},
			closeModal() {
				this.showModal = false;
			},
			openModal() {
				this.showModal = true;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.card-global { margin-bottom: 1rem; }
	.card-sub-card { background-color: $grey-lighter; margin: 1rem; }
	.card-title { display: flex; align-items: center; color: $blackish; }
	.card-title h3 { font-size: 1.4rem; font-weight: 500; }
	.card-title .circle {
		width: 30px; height: 30px; border-radius: 50%;
		border: 1px solid $grey-dark; background-color: $grey-lighter;
		color: $blackish; display: flex; justify-content: center;
		align-items: center; font-weight: bold; margin-right: 1rem;
	}
	.cancel-button { border: 1px solid $blackish; color: $blackish; background-color: transparent; }
	.add-to-cart-button { border: 1px solid $blackish; background-color: $blackish; color: white; }
	.add-to-cart-button.v-btn--disabled { border: 1px solid $grey-light; background-color: $grey-light; }
	.side-modal-overlay {
		position: fixed;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: flex-end;
		align-items: center;
		z-index: 999;
	}
	.side-modal {
		background-color: white;
		width: 50vw;
		max-width: 800px;
		height: 100vh;
		box-shadow: -4px 0 10px rgba(0, 0, 0, 0.1);
		padding: 0px;
		overflow-y: auto;
		transition: transform 0.3s ease-in-out;
	}
	.side-modal-header {
		display: flex;
		justify-content: flex-end;
	}
	.side-modal-header button {
		font-size: 1.5rem;
		background: none;
		border: none;
		color: #333;
		cursor: pointer;
		transition: color 0.3s;
	}
	.side-modal-header button:hover {
		color: red;
	}
	.side-modal-content {
		padding: 10px 0;
	}
	@media (max-width: 768px) {
		.side-modal {
			width: 100vw;
			height: 85%;
			box-shadow: none;
			border-radius: 20px 20px 0 0;
			position: fixed;
			bottom: 0;
			transform: translateY(100%);
			padding: 10px;
		}
		.side-modal-overlay {
			display: flex;
			justify-content: center;
			align-items: flex-end;
		}
		.side-modal.show {
			transform: translateY(0);
		}
	}

</style>
