<template>
	<div>
		<v-row :justify="align === 'left' ? 'start' : 'end'" class="counter-row">
			<v-btn icon @click="decrement" class="circular-button mx-1" :disabled="!canDecrement">
				<v-icon>mdi-minus</v-icon>
			</v-btn>
			<span class="counter-value"
				:class="isModifier ? 'mx-6' : 'mx-16'">{{ currentQuantity }}</span>
			<v-btn icon @click="increment" class="circular-button mx-1" :disabled="!canIncrement">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-row>
		<v-row class="mt-4" v-if="minQuantity || maxQuantity">
			<span class="text-caption text-grey-dark opacity-50" :class="{'ml-auto' : align === 'right', 'ml-2' : align === 'left'}">
				<span v-if="minQuantity && maxQuantity">{{$t('product.quantityBoth', {min : minQuantity, max : maxQuantity})}}&nbsp;</span>
				<span v-else-if="minQuantity">(Min {{ minQuantity }})&nbsp;</span>
				<span v-else-if="minQuantity">{{$t('product.quantityMin', {min : minQuantity})}}</span>
				<span v-else-if="maxQuantity">{{$t('product.quantityMax', {max : maxQuantity})}}</span>
			</span>
		</v-row>
	</div>
</template>

<script>
	export default {
		name: 'QuantityPicker',
		props: {
			currentQuantity: {
				type: Number,
				default: 0
			},
			minQuantity: {
				type: Number | null,
				default: null
			},
			maxQuantity: {
				type: Number | null,
				default: null
			},
			incrementValue: {
				type: Number | null,
				default: 1
			},
			align: {
				type: String,
				default: 'left'
			},
			isModifier: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			canIncrement() {
				return !this.maxQuantity || this.currentQuantity < this.maxQuantity;
			},
			canDecrement() {
				return this.currentQuantity > 0;
			},
			valueStep() {
				return this.incrementValue !== null ? this.incrementValue : 1;
			}
		},
		methods: {
			increment() {
				if (this.canIncrement) {
					let nextQuantity = 0;
					if (this.currentQuantity == 0) {
						nextQuantity = this.minQuantity ? this.minQuantity : this.valueStep;
					} else {
						nextQuantity = this.currentQuantity + this.valueStep;
					}
					this.$emit("quantityChanged", this.maxQuantity && nextQuantity > this.maxQuantity ? this.maxQuantity : nextQuantity);
				}
			},
			decrement() {
				if (this.canDecrement) {
					let nextQuantity = 0

					if (this.minQuantity % this.maxQuantity !== 0 && this.currentQuantity == this.maxQuantity) {
						nextQuantity = this.currentQuantity - 1;
					} else {
						nextQuantity = this.currentQuantity !== this.minQuantity ? this.currentQuantity - this.valueStep : 0;
					}
					this.$emit("quantityChanged", nextQuantity);
				}
			}

		}
	};
</script>

<style scoped lang="scss">
	.counter-value {
		font-weight: bold;
	}
	.circular-button {
		border: 1px solid $grey-light;
		border-radius: 50%;
		width: 40px;
		height: 40px;
	}
	.counter-row {
		display: flex;
		align-items: center;
	}
</style>
