<template>
	<div>
		<v-container fluid>
			<v-row v-for="product in products" :key="product.id" class="align-center mt-0">

				<v-col cols="12" class="d-flex justify-space-between grey-dark pb-0">
					<div class="name">
						{{ displayQuantity(product.quantity) }} <span class="ml-3">{{ product.name }}</span>
					</div>
					<div v-if="product.price" class="price">
						{{ $options.filters.currencyWithoutCountry(calculateProductTotal(product)) }}
					</div>
				</v-col>

				<v-col cols="12" v-if="product.modifiers.length" class="pt-0">
					<div v-for="modifier in product.modifiers" :key="modifier.name" class="ml-9 mt-3">
						{{ displayQuantity(modifier.quantity) }} <span class="ml-3">{{ modifier.name }}</span>
					</div>
				</v-col>
			</v-row>

			<hr class="mt-5 mb-5 divider">

			<v-row class="total" v-if="subTotal">
				<v-col>
					<div>{{ $t('product.subTotal') }} :</div>
				</v-col>
				<v-col class="text-right">
					<div>{{ $options.filters.currencyWithoutCountry(subTotal) }}</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	export default {
		name: "ProductsSubTotalList",
		props: {
			products: {
				type: Array,
				required : true,
			},
		},
		mounted(){
			this.formatModifier()
		},
		computed: {
			subTotal() {
				return this.products.reduce((sum, product) => {
					return sum + product.price;
				}, 0).toFixed(2);
			}
		},
		watch: {
			products() {
				this.formatModifier();
			}
		},
		methods: {
			formatModifier() {
				this.products.forEach((product) => {
					const modifiers = [];
					if (product.modifiers.size > 0) {
						product.modifiers.forEach((item) => {
							modifiers.push({
								quantity: item.quantity,
								name: item.modifier.name,
								price: item.modifier.price,
							})
						});
					}
					product.modifiers = modifiers;
				})
			},
			calculateProductTotal(product) {
				return product.price.toFixed(2);
			},
			displayQuantity(quantity) {
				return `${quantity}x`;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.grey-light {
		color: $grey-light;
	}
	.grey-dark {
		color: $grey-dark;
	}
	.price {
		font-weight: bold;
		color: $grey-dark;
	}
	.divider {
		border-top: 1px solid $grey-light;
	}
	.total {
		font-size: 1.3rem;
		font-weight: 600;
	}
</style>
