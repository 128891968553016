<template>
	<div>
	<Page color="neutralLight" breadcrumbs no-sidebar>
		<v-container class="py-md-0 px-md-6 mt-3">
			<!-- TITLE -->
			<v-skeleton-loader v-if="skeleton" type="heading" />
			<h2 v-else class="display-1"v-text="$options.filters.translatable(details.data.name, details.data.name_i18n)"/>

			<!-- COLUMNS -->
			<v-row class="mt-7">
				<!-- TAG DESCRIPTION -->
				<v-col cols="12" md="5">
					<Sticky class="pb-6">
						<v-skeleton-loader v-if="skeleton" type="image" />
						<v-img v-else
							:aspect-ratio="16 /9"
							:src="details.data.background"
							class="rounded-lg"/>

						<div class="mt-4">
							<v-skeleton-loader v-if="skeleton" type="paragraph" />
							<p v-else class="body-1 font-weight-light">
								<span v-html="formattedDescription" />
								<a v-if="canShowAllDesc"
									class="secondaryDark--text"
									href
									@click.stop.prevent="showAllDesc = !showAllDesc"
									v-text="showAllDesc ? $t('product.readLess') : $t('product.readMore')"/>
							</p>
						</div>
					</Sticky>
				</v-col>

				<!-- STEPS -->
				<v-col cols="12" md="7" class="d-flex flex-column">
					<!-- PRODUCTS STEP-->
						<v-skeleton-loader  v-if="skeleton" type="card"  />
						<template v-else>
							<template v-if="tagProducts.length > 0">
								<ProductCard
									v-for="(product, index) in tagProducts"
									class="mb-8"
									:key="`tagProduct-${product.data.guid}-${index}`"
									:product="product"
									:productsSelected="productsSelected"
									@quantityChanged="updateProductQuantity"
									@showProductDetailModal="openProductDetailModal"
									@quantityModifierChanged="updateModifierQuantity"/>

								<!-- Product Modal -->
								<ProductDetailModal
									v-if="selectedProductForDetailModal"
									:product="selectedProductForDetailModal"
									:productsSelected="productsSelected"
									:isOpen="showProductDetailModal"
									:with-time-based-selection="hasCalendar"
									@quantityChanged="updateProductQuantity"
									@goNext="goNext"
									@addToCart="addToCart"
									@close="showProductDetailModal = false"
								/>
							</template>
							<v-alert v-else type="info" prominent text>
								<span v-text="$t('tag.noProducts')"></span>
							</v-alert>
						</template>

					<!-- CALENDAR STEP-->
					<ProductPackageSelector
						v-if="!noProduct"
						ref="ProductPackageSelector"
						:productsSelected="productsSelected"
						@addToCartSuccess="handleAddToCart"/>
				</v-col>
			</v-row>

				<CrossSellsModal
					:crossSellProducts="crossSellProducts"
					:isOpen="showCrossSellModal"
					@close="showCrossSellModal = false"/>
		</v-container>
	</Page>

	<StickyBottomActions
		class="mt-4"
		:back-label="$t('tag.continueShopping')"
		:next-label="nextBtnLabel"
		:next-disabled="nextDisabled"
		:active="!nextDisabled"
		@backClicked="goBack"
		@nextClicked="goNext" />

	</div>
</template>

<script>
	import Page from '@/components/Page.vue'
	import { BaseModel, EComService, PaginationModel, ProductModel } from '@connectngo/sdk'
	import Text from '@/utils/Text'
	import { WindowWidthMixin } from '@/mixins/ProductMixin'
	import ProductV2Mixin from '@/mixins/ProductV2Mixin';
	import Sticky from '@/components/Sticky.vue'
	import StickyBottomActions from '@/components/Shared/StickyBottomActions'
	import ProductCard from '@/components/ProductV2/ProductCard'
	import ProductPackageSelector from '@/components/ProductV2/ProductPackageSelector'
	import CrossSellsModal from "@/components/ProductV2/CrossSellsModal";
	import ProductDetailModal from '@/components/ProductV2/ProductDetailModal';


	export default {
		name: 'TagV2',
		components: {
			ProductCard,
			StickyBottomActions,
			Sticky,
			Page,
			ProductDetailModal,
			ProductPackageSelector,
			CrossSellsModal
		},
		mixins : [WindowWidthMixin, ProductV2Mixin],
		data() {
			return {
				skeleton: true,
				loading: true,
				details: new BaseModel(),
				tagProducts: new PaginationModel(),
				title: '',
				showAllDesc: false,
				showCheckout: false,
				productsSelected : new Map(),
				noProduct : true,
				hasProductInCart: false,
				selectedProductForDetailModal: null,
				showProductDetailModal: false,
				showCrossSellModal: false,
				crossSellProducts:[],
			}
		},
		metaInfo() {
			return {
				title: this.title,
				meta:  this.getMeta()
			}
		},
		computed : {
			description() {
				return this.$options.filters.translatable(
					"",
					this.details.data.description_i18n,
					this.$i18n.locale
				) || ''
			},
			canShowAllDesc() {
				return this.description.length > 200 && this.isMobile;
			},
			formattedDescription() {
				const trimmed = Text.trimNotWithinWords(
					this.description.replace(/<\/?[^>]+(>|$)/g, "")
				);
				return this.isMobile && this.canShowAllDesc && !this.showAllDesc
					? trimmed.length === this.description.length
						? trimmed
						: trimmed + "..."
					: this.description;
			},
			nextBtnLabel() {
				if (this.showCheckout && this.noProduct) {
					return this.$t('btn.proceedCheckout');
				}

				if ((this.noProduct || !this.hasCalendar) && (!this.hasProductInCart || !this.noProduct)) {
					return this.$t('btn.addToCart');
				}

				return this.hasCalendar ? this.$t('tag.chooseVisitingDate') : this.$t('btn.proceedCheckout');
			},
			isHardCrossSell() {
				return this.$root.websiteConfig.data.fields.hard_cross_sell === "1";
			},
			nextDisabled() {
				return !this.hasProductInCart && this.noProduct;
			}
		},
		created () {
			this.title = this.$i18n.t('route.tags');
			this.$route.meta.name = this.$i18n.t('state.loading');
			this.getTag()
			if (this.isHardCrossSell) {
				this.getCrossSell()
			}
		},
		watch: {
			productsSelected() {
				if (this.productsSelected.size > 0) {
					this.noProduct = false;
					this.showCheckout = false;
				}
			}
		},
		methods: {
			getCrossSell(){
				new EComService().getCrossSell(this.$route.params.key)
					.then(crossSell => {
						this.crossSellProducts = crossSell.results;
					})
					.catch((reason) => this.$handleError(this, reason))
					.finally(() => (this.loading = false));
			},
			finalizeGetTag() {
				this.loading = false
				//check for product slug, so we can open modal if available
				const slug = this.$route.params.slug || window.location.hash.slice(1);
				if (slug) {
					const product = this.tagProducts.find(p => p.data.slug === slug);
					if (product) {
						this.openProductDetailModal(product);
					}
				}
			},
			getTag () {
				this.loading = true

				return new EComService().getTagDetails(this.$route.params.key)
					.then((tag) => {
						// Prevent navigation for reload tag from slug
						if (tag.data.reloadable) {
							return this.$router.push({ name: 'reloadV2' })
						}

						this.details = tag;
						this.title = this.$options.filters.translatable(
							tag.data.name,
							tag.data.name_i18n
						);

						this.$route.meta.name = this.title;
						this.$breadcrumbs.refresh();

						this.tagProducts = tag.data.products
							.map(product => new ProductModel(product.data))

						this.skeleton = false;
					})
					.catch((error) => {
						if(error.response?.status === 404) {
							this.$router.push({ name: "error404" });
						} else {
							this.$handleError(this, error)
						}
					})
					.finally(this.finalizeGetTag)
			},
			getMeta() {
				return [
					{
						property: 'description',
						content: this.$options.filters.translatable(
							this.details.data.description,
							this.details.data.description_i18n,
							this.$i18n.locale
						),
					},
					{
						property: 'og:title',
						content: this.$options.filters.translatable(
							this.details.data.name,
							this.details.data.name_i18n,
							this.$i18n.locale
						),
					},
					{ property: 'og:url', content: window.location.href },
					{
						property: 'og:description',
						content: this.$options.filters.translatable(
							this.details.data.description,
							this.details.data.description_i18n,
							this.$i18n.locale
						),
					},
					{ property: 'og:image', content: this.details.data.background },
					{
						property: 'twitter:image:src',
						content: this.details.data.background,
					},
				];
			},
			goNext() {
				if (this.shouldGoToCart() && this.crossSellProducts.length > 0) {
					this.showCrossSellModal = true;
					return;
				}
				if (this.shouldGoToCart()) {
					this.$router.push({ name: "cart" });
					return;
				}

				if (this.noProduct) {
					return;
				}

				this.hasCalendar
					? this.$refs.ProductPackageSelector.openModal()
					: this.addToCart();
			},
			shouldGoToCart() {
				return (
					(this.showCheckout && !this.showProductDetailModal) ||
					(this.hasProductInCart && this.noProduct)
				);
			},
			goBack() {
				this.$router.push({name : 'home'});
			},
			updateProductQuantity(product, quantity) {
				if (quantity > 0) {
					if (this.productsSelected.has(product.data.id)) {

						const productData = this.productsSelected.get(product.data.id);
						productData.quantity = quantity;

						this.productsSelected.set(product.data.id, productData);
					} else {
						this.productsSelected.set(product.data.id, {
							id: product.data.id,
							product,
							quantity,
							modifiers: new Map()
						});
					}
				} else {
					this.productsSelected.delete(product.data.id);
				}

				this.noProduct = this.productsSelected.size === 0;

				this.productsSelected = new Map(this.productsSelected);
			},
			openProductDetailModal(product) {
				history.pushState(null, null, `#${product.data.slug}`);
				this.selectedProductForDetailModal = product;
				this.showProductDetailModal = false;

				this.$nextTick(() => {
					this.showProductDetailModal = true;
				});
			},
			handleAddToCart() {
				this.productsSelected = new Map();
				this.showCheckout = true;
				this.hasProductInCart = true;
				this.noProduct = true;
			}
		}
	}
</script>

<style lang="scss" scoped>
	@media (max-width: 600px) {
		.container {
			padding: 25px;
		}
	}
</style>
