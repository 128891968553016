<template>
	<div class="mt-10">
		<div v-if="modifier.required" class="d-flex justify-space-between align-center mb-2">
			<div> <span class="text-blackish text-bold">{{ modifier.name }}</span>  <span class="opacity-50">(required)</span> </div>
			<div class="text-blackish text-bold">{{ $options.filters.currencyWithoutCountry(modifier.price) }}</div>
		</div>
		<div v-else>
			<v-card
				class="modifier-card rounded-lg d-flex align-center mt-4"
				:class="{
					'pa-3': (modifier.min_quantity || modifier.max_quantity),
					'pa-5': !(modifier.min_quantity || modifier.max_quantity)
				}"
				flat>
				<v-col cols="5">
					<div class="text-blackish text-bold">{{ modifier.name }}</div>
				</v-col>
				<v-col cols="5">
					<QuantityPicker
						v-if="!isInModal"
						:current-quantity="currentQuantity"
						:min-quantity="modifier.min_quantity"
						:max-quantity="modifier.max_quantity"
						:incrementValue="modifier.increment"
						align="right"
						:isModifier="true"
						class="quantity-picker"
						@quantityChanged="emit"/>
				</v-col>
				<v-col cols="2" align="end">
					<div class="text-blackish text-bold">{{ $options.filters.currencyWithoutCountry(modifier.price) }}</div>
				</v-col>
			</v-card>
		</div>
	</div>
</template>

<script>
	import QuantityPicker from '@/components/Shared/QuantityPicker';
	export default {
		name: "ProductModifiers",
		components: {
			QuantityPicker
		},
		props: {
			modifier: {
				type: Object,
				required: true,
			},
			requiredModifiers: {
				type: Boolean,
				default: false
			},
			isInModal: {
				type: Boolean,
				default: false
			},
			product: {
				type: Object,
				required: true,
			},
			productsSelected: {
				type: Map,
				required: true
			},
		},
		computed: {
			padding() {
				if (modifier.min_quantity || modifier.max_quantity) {
					return "pa-3";
				}
				return "pa-6"
			},
			currentQuantity() {
				const productSelected = this.productsSelected.get(this.product.data.id);
				if (productSelected) {
					if (productSelected.modifiers) {
						const modifierSelected = productSelected.modifiers.get(this.modifier.id);
						return modifierSelected ? modifierSelected.quantity : 0;
					}
				}
			},
		},
		methods: {
			emit(newQuantity) {
				this.$emit("quantityModifierChanged", this.modifier, newQuantity);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.modifier-card {
		width: 100%;
		border: 1px solid $light-grey;
	}
	.text-blackish {
		color: $blackish;
	}
	.text-bold {
		font-weight: 600;
	}
</style>
